import { Box, Button, Typography } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useRef, useState } from "react";
import DropDownComponent from "../../Component/DropDownComponent";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import defaultUserImage from "../../Assets/Image/profile.png";
import view from "../../Assets/Image/newView.svg";
import { toast } from "react-toastify";
import UserDetailsModal from "./UserDetailsModal";
import ConfirmationModal from "../../Component/ConfirmationModal";
import logo from "../../Assets/Image/question.svg";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
import block from "../../Assets/Image/block.svg";
import unblock from "../../Assets/Image/unblock.svg";
import deleted from "../../Assets/Image/Delete.svg";
const UserScreen = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [userDetailsModalId, setUserDetailsModalId] = useState("");
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const handleOpen = () => setOpenDetailsModal(true);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filter, setFilter] = useState("status != -1 and role != 3");
  const [open, setOpen] = useState(false);
  const [actionParams, setActionParams] = useState({
    userId: null,
    status: null,
  });

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleAction = async (userId, status) => {
    try {
      const f = new FormData();
      f.append("status", status);
      f.append("user_id", userId);
      const res = await AXIOS.post(`${apiList.user.action}`, f);
      if (res?.s) {
        status === 1 ? toast.success("Unblocked") : toast.error("Blocked");
        getData(
          page,
          rowsPerPage,
          filter,
          filterRef.current,
          debouncedSearchTerm,
          searchRef.current
        );
      } else {
        toast.error(res?.m);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterOption = [
    { label: "All", value: "status != -1 and role != 3" },
    { label: "Free", value: "status != -1 and role = 1" },
    { label: "Premium", value: "status != -1 and role = 2" },
    { label: "Suspended", value: "status = 0 and role != 3" },
  ];

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 80,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid + page * rowsPerPage;
      },
    },
    {
      field: "profile_img",
      headerName: "Profile",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={
              cell.row.profile_img
                ? apiList.baseURL + cell.row.profile_img
                : defaultUserImage
            }
            alt="profile"
            style={{ borderRadius: "12px", height: "35px", width: "35px" }}
          />
        </div>
      ),
    },
    {
      field: "username",
      headerName: "Full Name",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "phno",
      headerName: "Number",
      width: 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.phno_cc && cell?.row?.phno
          ? cell?.row?.phno_cc + " " + cell?.row?.phno
          : "-";
      },
    },
    // {
    //   field: "dob",
    //   headerName: "Date of Birth",
    //   minWidth: 200,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "center",
    //   display: "flex",
    //   align: "center",
    //   flex: 1,
    //   renderCell: (cell) => {
    //     return cell?.row?.dob ? moment(cell?.row?.dob).format("LL") : "-";
    //   },
    // },
    // {
    //   field: "gender",
    //   headerName: "Gender",
    //   width: 120,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "center",
    //   display: "flex",
    //   align: "center",
    //   renderCell: (cell) => {
    //     const g = cell?.row?.gender;
    //     return g === 1 ? "Male" : g === 2 ? "Female" : g === 3 ? "Other" : "-";
    //   },
    // },
    {
      field: "is_approve",
      headerName: "Premium",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const g = cell?.row?.role;
        return g === 2 ? (
          <span
            style={{
              color: "#000",
              border: "2px solid #FD5D29",
              width: "100px",
              padding: 1,
              borderRadius: "5px",
              height: "100%",
            }}
          >
            Free
          </span>
        ) : (
          g === 1 && (
            <span
              style={{
                color: "#000",
                border: "2px solid #FD5D29",
                width: "100px",
                padding: 1,
                borderRadius: "5px",
                height: "100%",
              }}
            >
              Free
            </span>
          )
        );
      },
    },

    {
      field: "status",
      headerName: "Action",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
              <img
                src={
                  cell?.row?.status === 1
                    ? block
                    : cell?.row?.status === 0
                    ? unblock
                    : cell?.row?.status === -1 && deleted
                }
                style={{ height: "29px", width: "29px" }}
                alt="Block"
                onClick={() => {
                  setOpen(true);
                  setActionParams({
                    userId: cell?.row?.id,
                    status: cell?.row?.status === 1 ? 0 : 1,
                  });
                }}
              />

              <img
                src={view}
                onClick={() => {
                  setUserDetailsModalId(cell?.row?.id);
                  // handleOpen()
                }}
                style={{ height: "29px", width: "29px" }}
                alt="View"
              />
            </Box>
          </Box>
        );
      },
    },
  ];

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    filter,
    prevFilter,
    search,
    prevSearch
  ) => {
    try {
      const count = page * rowsPerPage;
      setLoading(true);
      if (filter !== prevFilter || search !== prevSearch) {
        setPage(0);
        setData([]);
      }
      let arg =
        search?.length > 0
          ? `${filter} and (username LIKE '%25${search}%25' OR email LIKE '%25${search}%25' OR phno LIKE '%25${search}%25')`
          : filter;
      const res = await AXIOS.get(
        `${apiList.user.getAll}&arg=${arg} &order=created_at desc&skip=${count}&limit=${rowsPerPage}&total=1`
      );
      if (res?.s) {
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const searchRef = useRef(debouncedSearchTerm);
  const filterRef = useRef(filter);

  useEffect(() => {
    getData(
      page,
      rowsPerPage,
      filter,
      filterRef.current,
      debouncedSearchTerm,
      searchRef.current
    );
    searchRef.current = debouncedSearchTerm;
    filterRef.current = filter;
  }, [debouncedSearchTerm, filter, rowsPerPage, page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box className="flexBetween">
        <Typography>
          <h2> User Management </h2>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "470px",
            width: "100%",
          }}
        >
          <input
            type="search"
            name="search"
            value={searchTerm}
            placeholder="Search user"
            autoFocus
            style={{
              padding: "13px",
              height: "100%",
              borderRadius: "12px",
              border: "1px solid #FD5D29",
              backgroundColor: "#FFF6F3",
              color: "#FD5D29",
              width: "100%",
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            id="search"
          />
        </Box>
        <Box sx={{}}>
          <DropDownComponent
            value={filter}
            options={filterOption}
            modal={false}
            handleChange={handleFilterChange}
            placeholder={"Status"}
            check={false}
          />
        </Box>
      </Box>
      <Box sx={{ height: "calc(100vh - 180px)" }}>
        <DataListingComponent
          data={data}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={loading}
          totalData={totalData}
          page={page}
          columns={columns}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
        <UserDetailsModal
          id={userDetailsModalId}
          open={openDetailsModal}
          setOpen={setOpenDetailsModal}
        />
        <ConfirmationModal1
          open={open}
          setOpen={setOpen}
          logo={logo}
          onConfirm={() =>
            handleAction(actionParams.userId, actionParams.status)
          }
          title="Confirm Action"
          message="Are you sure?"
          confirmText="Confirm"
          cancelText="Cancel"
        />
      </Box>
    </Box>
  );
};

export default UserScreen;
