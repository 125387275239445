import "./App.css";
import AllRoutes from "./Component/AllRoutes";
import 'react-quill/dist/quill.snow.css';

function App() {
  return (
    <>
      <AllRoutes />
    </>
  );
}

export default App;
