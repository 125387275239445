import { Box } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";

const QuillEditerComponent = ({ formik }) => {
  return (
    <>
      <Box
        sx={{
          color: "#FFF",
          width: "100%",
          textAlign: "left",
          borderRadius: "10px",
          boxSizing: "border-box",
          p: "2px",
          // background: `#FD5D29`,
          mt: "15px",
          "& .ql-container": {
            minHeight: "400px",
            maxHeight: "calc(100vh - 130px)",
            overflow: "auto",
            resize: "both",

            // backgroundColor: "transparent",
            background: "#FFF",
            color: "#FD5D29",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            // backgroundColor: "#fff",
          },
          "& .ql-toolbar": {
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            zIndex: 999,
            background: `#FD5D29`,
          },
          "& .ql-toolbar .ql-picker": {
            color: "#FFF", // Ensure dropdown pickers have white color
          },
          "& .ql-toolbar button": {
            color: "#FFF !important", // Ensure buttons have white color
          },
          "& .ql-toolbar .ql-stroke": {
            stroke: "#FFF", // Ensure toolbar icons have white stroke color
          },
          "& .ql-toolbar .ql-fill": {
            color: "#FFF !important", // Ensure toolbar icons have white fill color
          },
          "& .ql-toolbar .ql-picker-options": {
            backgroundColor: "#333", // Adjust dropdown background for better contrast
            color: "#FFF", // Ensure dropdown options have white color
          },
          "& .ql-toolbar .ql-picker-label": {
            color: "#FFF !important", // Ensure picker label has white color
          },
          "& .ql-toolbar button:focus": {
            backgroundColor: "rgba(119, 82, 254, 1) !important", // Ensure focus background color
            color: "#FFF !important", // Ensure text color is white on focus
          },
          ".ql-editor.ql-blank::before": {
            color: "#FFF75",
            fontWeight: "500",
          },
        }}
      >
        <ReactQuill
          placeholder="Content"
          value={formik.values.content}
          //   ref={ref}
          onChange={(content) => {
            content = content.replace(/(^<[^>]*>)([\s]*)/gm, "$1");
            formik.setFieldValue("content", content);
          }}
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["bold", "italic", "underline", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link"],
              ["clean"],
              [
                { align: "right" },
                { align: "center" },
                { align: "justify" },
                { align: "" },
              ],
              [
                {
                  color: [
                    "black",
                    "#D8D4FF",
                    "gray",
                    "silver",
                    "maroon",
                    "red",
                    "purple",
                    "green",
                    "lime",
                    "yellow",
                    "navy",
                    "blue",
                    "teal",
                    "aqua",
                  ],
                },
                {
                  background: [
                    "transparent",
                    "#f5f5f5", // Light Grey
                    "#dcdcdc", // Gainsboro
                    "#c0c0c0", // Silver
                    "#a9a9a9", // Dark Grey
                    "#808080", // Grey
                    "#696969", // Dim Grey
                    "#595959", // Dark Grey
                    "#404040", // Black
                    "#2f2f2f", // Dark Grey
                    "#202020", // Dark Grey
                    "#101010", // Black
                    "#ff0000", // Red
                    "#00ff00", // Green
                    "#0000ff", // Blue
                    "#ffff00", // Yellow
                    "#ff00ff", // Magenta
                    "#00ffff", // Cyan
                    "#808080", // Gray
                    "#800080", // Purple
                    "#008000", // Green
                    "#000080", // Navy
                    "#808000", // Olive
                    "#ffa500", // Orange
                    "#ff69b4", // Pink
                    "#ffdab9", // Peach
                    "#fa8072", // Salmon
                    "#a52a2a", // Brown
                    "#008080", // Teal
                    "#00ffff", // Cyan
                    "#90ee90", // Light Green
                    "#ffb6c1", // Pink
                    "#fffaf0", // Floral White
                    "#ffffe0", // Light Yellow
                  ],
                },
              ],
            ],
          }}
        />
        {formik.errors.content && formik.touched.content ? (
          <div>{formik.errors.content}</div>
        ) : null}
      </Box>
    </>
  );
};

export default QuillEditerComponent;
