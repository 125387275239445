import React, { useRef, useState, useEffect } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { OTPInput } from "input-otp";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { RouteList } from "../../Component/RoutesList";

const VerifyOTPScreen = ({ setCurrentScreen }) => {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [timer, setTimer] = useState(120);
  const navigate = useNavigate();
  const submitButtonRef = useRef(null);
  const [isResend, setIsResend] = useState(false);

  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required("OTP is required")
      .matches(/^\d{6}$/, "OTP must be a 6-digit number"),
  });

  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        let email = localStorage.getItem("email");
        let hash = localStorage.getItem("fp_token");
        const res = await AXIOS.post(apiList.auth.verifyOTP, {
          email: email,
          otp: values.otp,
          hash: hash,
        });
        if (res?.s) {
          toast.success(res?.m);
          setCurrentScreen(4);
          navigate(RouteList?.login, { state: { id: 4 } });
        } else {
          toast.error(res.m);
        }
      } catch (error) {
        toast.error("Error verifying OTP");
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const resendOtp = async () => {
    let email = localStorage.getItem("email");
    try {
      setLoading1(true); // Start the loading spinner for resend
      const res = await AXIOS.post(apiList.auth.forgot, {
        email: email,
      });
      if (res?.s) {
        toast.success(res?.m);
        localStorage.setItem("fp_token", res?.r?.fp_token);
        localStorage.setItem("email", res?.r?.email);

        // Reset the timer and hide the resend button
        setTimer(120); // Restart the timer
        setIsResend(true); // Hide the resend button
      } else {
        toast.error(res?.m);
      }
    } catch (error) {
      toast.error("Error resending OTP");
      console.error(error);
    } finally {
      setLoading1(false); // Stop the loading spinner for resend
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          setIsResend(false); // Show the resend button when timer ends
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const secondsToMinutesAndSeconds = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        OTP Verification
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Please enter the 6-digit OTP sent to your email.
      </Typography>
      <Box sx={{ width: "100%" }}>
        <OTPInput
          maxLength={6}
          value={formik.values.otp}
          onChange={(value) => formik.setFieldValue("otp", value)}
          containerClassName="otp-container"
          render={({ slots }) => (
            <div style={{ display: "flex", gap: "10px" }}>
              {slots.map((slot, idx) => (
                <Box
                  key={idx}
                  sx={{
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "8px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {slot.char}
                </Box>
              ))}
            </div>
          )}
        />
        {formik.touched.otp && formik.errors.otp && (
          <Typography color="error" sx={{ mt: 1 }}>
            {formik.errors.otp}
          </Typography>
        )}
        <Box className="flexCenter">
          <Button
            ref={submitButtonRef}
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              width: "70%",
              textTransform: "none",
              borderRadius: "15px",
              fontSize: "17px",
              background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
              "&:hover": {
                background: "#00075",
              },
              padding: "12px",
            }}
            disabled={loading}
            onClick={formik.handleSubmit}
          >
            {loading ? <CircularProgress size={24} /> : "Verify OTP"}
          </Button>
        </Box>
        <Typography
          sx={{
            mt: 3,
            textAlign: "center",
            display: timer > 0 ? "block" : "none",
          }}
        >
          Resend OTP in{" "}
          <span style={{ color: "#fd5d29" }}>
            {timer > 0 ? secondsToMinutesAndSeconds(timer) : "00:00"}
          </span>
        </Typography>
        {timer <= 0 && (
          <Typography
            sx={{
              display: isResend ? "none" : "block",
              mt: 2,
              textAlign: "center",
              cursor: "pointer",
              color: "#fd5d29",
            }}
            onClick={resendOtp}
          >
            {loading1 ? <CircularProgress size={24} /> : "Resend OTP"}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default VerifyOTPScreen;
