import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useState } from "react";
import { apiList } from "../../Component/apiList";
import defaultUserImage from "../../Assets/Image/profile.png";
import { AXIOS } from "../../Component/apiHelper";
import { toast } from "react-toastify";

const rows = [
  { id: 1, name: "John Doe", age: 25 },
  { id: 2, name: "Jane Smith", age: 30 },
  { id: 3, name: "Alice Johnson", age: 22 },
  { id: 4, name: "Robert Brown", age: 28 },
  { id: 5, name: "Emily Davis", age: 35 },
  { id: 6, name: "Michael Wilson", age: 40 },
  { id: 7, name: "Sophia Taylor", age: 29 },
  { id: 8, name: "William White", age: 32 },
  { id: 9, name: "Olivia Martin", age: 24 },
  { id: 10, name: "James Thompson", age: 27 },
];
const RecentUserComponent = () => {
  const [data, setData] = useState(rows);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid;
      },
    },
    {
      field: "profile_img",
      headerName: "Profile",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={
              cell?.row?.profile_img
                ? apiList.baseURL + cell?.row?.profile_img
                : defaultUserImage
            }
            alt="profile"
            style={{ borderRadius: "50%", height: "35px", width: "35px" }}
          />
        </div>
      ),
    },
    {
      field: "username",
      headerName: "Full Name",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "phno",
      headerName: "Number",
      width: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.phno_cc && cell?.row?.phno
          ? cell?.row?.phno_cc + " " + cell?.row?.phno
          : "-";
      },
    },
  ];

  const getData = async () => {
    try {
      setLoading(true);

      const res = await AXIOS.get(
        `${apiList.user.getAll}&arg=status != -1 and role != 3 &order=created_at desc&skip=0&limit=10&total=1`
      );
      if (res?.s) {
        // const updatedData = count === 0 ? res?.r : [...data, ...res?.r];
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        setLoading(false);
      } else {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <DataListingComponent
        data={data}
        loading={loading}
        columns={columns}
        hideFooter={true}
      />
    </>
  );
};

export default RecentUserComponent;
