import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import app from "../../Assets/Image/User Block-1.svg";
import rej from "../../Assets/Image/User Block.svg";
import { toast } from "react-toastify";
import { apiList } from "../../Component/apiList";
import { AXIOS } from "../../Component/apiHelper";

const RecentBusinessComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid;
      },
    },

    {
      field: "username",
      headerName: "User Name",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
      renderCell: (cell) => {
        return cell?.row?.user_details?.username;
      },
    },
    {
      field: "business_name",
      headerName: "Business Name",
      minWidth: 250,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    // {
    //   field: "category_name",
    //   headerName: "Category Name",
    //   minWidth: 250,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "center",
    //   display: "flex",
    //   align: "center",
    //   flex: 1,
    // },

    {
      field: "apprrove",
      headerName: "Action",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const s = cell?.row?.user_details?.is_approve;
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            {s == 0 ? (
              <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
                <img
                  src={rej}
                  alt="Block"
                  style={{ height: "25px", width: "25px" }}
                  onClick={() =>
                    handleBusinessAction(cell?.row?.user_details?.id, 1)
                  }
                />
                <img
                  src={app}
                  style={{ height: "25px", width: "25px" }}
                  alt="View"
                  onClick={() =>
                    handleBusinessAction(cell?.row?.user_details?.id, -1)
                  }
                />
              </Box>
            ) : s === 1 ? (
              <span style={{ color: "green" }}>Approved</span>
            ) : (
              s === -1 && <span style={{ color: "red" }}>Rejected</span>
            )}
          </Box>
        );
      },
    },
  ];

  const handleBusinessAction = async (userId, status) => {
    try {
      const res = await AXIOS.post(`${apiList.business.action}/${userId}`, {
        is_approve: status,
      });
      if (res?.s) {
        status === 1 ? toast.success("Approved") : toast.error("Rejected");
        getData();
      } else {
        toast.error(res?.m);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const res = await AXIOS.get(
        `/api/base/get/?select=*&from=user_business_view&arg=status = ? and user_id in (select user_id FROM user_identity where user_id = user_business_view.user_id)  &prms=[1]&childEntity={"user_details":{"select":"id,username,profile_img,is_approve","from":"user_view","arg":"id = ?","limit":1,"prms":[":;user_id"]}}&order=created_at desc&skip=${0}&limit=${10}&total=1`
      );
      if (res?.s) {
        // const updatedData = count === 0 ? res?.r : [...data, ...res?.r];
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        setLoading(false);
      } else {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <DataListingComponent
        data={data}
        loading={loading}
        columns={columns}
        hideFooter={true}
      />
    </>
  );
};

export default RecentBusinessComponent;
