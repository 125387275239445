export const DashBoardSVG = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33337 10.1334C5.33337 7.87063 5.33337 6.73926 6.03632 6.03632C6.73926 5.33337 7.87063 5.33337 10.1334 5.33337C12.3961 5.33337 13.5275 5.33337 14.2304 6.03632C14.9334 6.73926 14.9334 7.87063 14.9334 10.1334C14.9334 12.3961 14.9334 13.5275 14.2304 14.2304C13.5275 14.9334 12.3961 14.9334 10.1334 14.9334C7.87063 14.9334 6.73926 14.9334 6.03632 14.2304C5.33337 13.5275 5.33337 12.3961 5.33337 10.1334Z"
        fill={color}
        //
      />
      <path
        d="M17.0667 21.8667C17.0667 19.6039 17.0667 18.4725 17.7696 17.7696C18.4725 17.0667 19.6039 17.0667 21.8667 17.0667C24.1294 17.0667 25.2608 17.0667 25.9637 17.7696C26.6667 18.4725 26.6667 19.6039 26.6667 21.8667C26.6667 24.1294 26.6667 25.2608 25.9637 25.9637C25.2608 26.6667 24.1294 26.6667 21.8667 26.6667C19.6039 26.6667 18.4725 26.6667 17.7696 25.9637C17.0667 25.2608 17.0667 24.1294 17.0667 21.8667Z"
        fill={color}
        //
      />
      <path
        d="M5.33337 21.8667C5.33337 19.6039 5.33337 18.4725 6.03632 17.7696C6.73926 17.0667 7.87063 17.0667 10.1334 17.0667C12.3961 17.0667 13.5275 17.0667 14.2304 17.7696C14.9334 18.4725 14.9334 19.6039 14.9334 21.8667C14.9334 24.1294 14.9334 25.2608 14.2304 25.9637C13.5275 26.6667 12.3961 26.6667 10.1334 26.6667C7.87063 26.6667 6.73926 26.6667 6.03632 25.9637C5.33337 25.2608 5.33337 24.1294 5.33337 21.8667Z"
        fill={color}
        //
      />
      <path
        d="M17.0667 10.1334C17.0667 7.87063 17.0667 6.73926 17.7696 6.03632C18.4725 5.33337 19.6039 5.33337 21.8667 5.33337C24.1294 5.33337 25.2608 5.33337 25.9637 6.03632C26.6667 6.73926 26.6667 7.87063 26.6667 10.1334C26.6667 12.3961 26.6667 13.5275 25.9637 14.2304C25.2608 14.9334 24.1294 14.9334 21.8667 14.9334C19.6039 14.9334 18.4725 14.9334 17.7696 14.2304C17.0667 13.5275 17.0667 12.3961 17.0667 10.1334Z"
        fill={color}
        //
      />
    </svg>
  );
};
export const DashFilterSVG = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 7H19.5M7 12H17M10 17H14"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const DashUserSVG = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="white" />
      <g clip-path="url(#clip0_1034_5017)">
        <path
          d="M16.2667 15.9996C18.0342 15.9996 19.4667 14.5671 19.4667 12.7996C19.4667 11.0321 18.0342 9.59961 16.2667 9.59961C14.4992 9.59961 13.0667 11.0321 13.0667 12.7996C13.0667 14.5671 14.4992 15.9996 16.2667 15.9996ZM18.5067 16.7996H18.0892C17.5342 17.0546 16.9167 17.1996 16.2667 17.1996C15.6167 17.1996 15.0017 17.0546 14.4442 16.7996H14.0267C12.1717 16.7996 10.6667 18.3046 10.6667 20.1596V21.1996C10.6667 21.8621 11.2042 22.3996 11.8667 22.3996H20.6667C21.3292 22.3996 21.8667 21.8621 21.8667 21.1996V20.1596C21.8667 18.3046 20.3617 16.7996 18.5067 16.7996Z"
          fill="#FD5D29"
        />
      </g>
      <defs>
        <clipPath id="clip0_1034_5017">
          <rect
            width="11.2"
            height="12.8"
            fill="white"
            transform="translate(10.6667 9.59961)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const DasBusinessSVG = ({ color }) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="30" fill="white" />
      <rect
        x="0.5"
        y="0.5"
        width="59"
        height="59"
        rx="29.5"
        stroke="#FD5D29"
        stroke-opacity="0.33"
      />
      <g clip-path="url(#clip0_679_1340)">
        <path
          d="M30.5 30C33.8141 30 36.5 27.3141 36.5 24C36.5 20.6859 33.8141 18 30.5 18C27.1859 18 24.5 20.6859 24.5 24C24.5 27.3141 27.1859 30 30.5 30ZM34.7 31.5H33.9172C32.8766 31.9781 31.7188 32.25 30.5 32.25C29.2812 32.25 28.1281 31.9781 27.0828 31.5H26.3C22.8219 31.5 20 34.3219 20 37.8V39.75C20 40.9922 21.0078 42 22.25 42H38.75C39.9922 42 41 40.9922 41 39.75V37.8C41 34.3219 38.1781 31.5 34.7 31.5Z"
          fill="#FD5D29"
        />
      </g>
      <defs>
        <clipPath id="clip0_679_1340">
          <rect
            width="21"
            height="24"
            fill="white"
            transform="translate(20 18)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const DasPremiumSVG = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="white" />
      <g clip-path="url(#clip0_998_6525)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.1797 24.9844V21.8203C10.1797 21.7031 10.2578 21.5859 10.4141 21.5859H12.7969C12.9141 21.5859 13.0312 21.7031 13.0312 21.8203V24.9844H10.1797ZM21.8203 24.9844V14.6328H20.7266C20.6484 14.6328 20.5703 14.5938 20.5312 14.5156C20.4922 14.4375 20.4922 14.3203 20.5312 14.2812L22.9922 10.5312C23.0312 10.4531 23.1094 10.4141 23.1875 10.4141C23.2656 10.4141 23.3438 10.4531 23.3828 10.5312L25.9609 14.2422C26 14.2812 26 14.3203 26 14.3984C26 14.5156 25.8828 14.6328 25.7656 14.6328H24.6719V24.9844H21.8203ZM17.9531 24.9844V17.9922C17.9531 17.875 18.0312 17.7578 18.1875 17.7578H20.5703C20.6875 17.7578 20.8047 17.875 20.8047 17.9922V24.9844H17.9531ZM14.0469 24.9844V20.2969C14.0469 20.1406 14.1641 20.0625 14.2812 20.0625H16.6641C16.8203 20.0625 16.8984 20.1406 16.8984 20.2969V24.9844H14.0469Z"
          fill="#FD5D29"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.375 25.9219H9.00781C8.89062 25.9219 8.77344 25.8047 8.77344 25.6875C8.77344 25.5312 8.89062 25.4531 9.00781 25.4531H25.375C25.4922 25.4531 25.6094 25.5312 25.6094 25.6875C25.6094 25.8047 25.4922 25.9219 25.375 25.9219Z"
          fill="#FD5D29"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.77331 20.6094C8.2655 20.6094 7.87487 20.2969 7.79675 19.7891C7.75768 19.3984 7.953 18.8516 8.61706 18.6953C9.04675 18.5391 9.47643 18.3438 9.828 18.1875C9.94518 18.1484 10.0624 18.1094 10.1405 18.0312C11.0389 17.6406 11.8983 17.1328 12.6796 16.5469C15.1014 14.7891 16.8592 12.5625 17.9139 9.94531C18.1092 9.47656 18.2655 8.96875 18.3827 8.5L18.4217 8.38281L17.1327 7.83594C17.0546 7.79688 16.9764 7.67969 16.9764 7.60156C16.9764 7.52344 17.0546 7.40625 17.1327 7.40625L20.2577 6.11719C20.3749 6.07812 20.4921 6.11719 20.5311 6.23438L21.8202 9.35938C21.8592 9.4375 21.8202 9.55469 21.7811 9.59375C21.703 9.67188 21.5858 9.71094 21.5077 9.67188L20.2577 9.125C19.7108 10.9609 18.8905 12.6406 17.8358 14.1641C16.7421 15.6484 15.3749 17.0156 13.8124 18.1484C12.7967 18.8906 11.703 19.5156 10.6092 19.9844C10.2186 20.1797 9.67175 20.4141 9.203 20.5703C9.04675 20.6094 8.92956 20.6094 8.77331 20.6094Z"
          fill="#FD5D29"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.2969 15.1016C7.91406 15.1016 6 13.1875 6 10.8047C6 8.46094 7.91406 6.50781 10.2969 6.50781C12.6797 6.50781 14.5938 8.46094 14.5938 10.8047C14.5938 13.1875 12.6797 15.1016 10.2969 15.1016ZM10.0625 13.3438C9.39844 13.2266 8.89062 12.6797 8.89062 11.9766C8.89062 11.8594 9.00781 11.7422 9.125 11.7422C9.28125 11.7422 9.35938 11.8594 9.35938 11.9766C9.35938 12.4062 9.67188 12.7578 10.0625 12.875V11.0391C9.39844 10.9219 8.89062 10.3359 8.89062 9.67188C8.89062 8.96875 9.39844 8.42188 10.0625 8.30469V8.14844C10.0625 8.03125 10.1797 7.91406 10.2969 7.91406C10.4141 7.91406 10.5312 8.03125 10.5312 8.14844V8.30469C11.1953 8.42188 11.6641 8.96875 11.6641 9.67188C11.6641 9.78906 11.5859 9.90625 11.4297 9.90625C11.3125 9.90625 11.1953 9.78906 11.1953 9.67188C11.1953 9.24219 10.9219 8.89062 10.5312 8.77344V10.6094C11.1953 10.7266 11.6641 11.2734 11.6641 11.9766C11.6641 12.6797 11.1953 13.2266 10.5312 13.3438V13.4609C10.5312 13.6172 10.4141 13.6953 10.2969 13.6953C10.1797 13.6953 10.0625 13.6172 10.0625 13.4609V13.3438ZM10.5312 12.875C10.9219 12.7578 11.1953 12.4062 11.1953 11.9766C11.1953 11.5469 10.9219 11.1953 10.5312 11.0781V12.875ZM10.0625 8.77344C9.67188 8.89062 9.35938 9.24219 9.35938 9.67188C9.35938 10.1016 9.67188 10.4531 10.0625 10.5703V8.77344Z"
          fill="#FD5D29"
        />
      </g>
      <defs>
        <clipPath id="clip0_998_6525">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const DasPostSVG = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="white" />
      <path
        d="M21.833 20.1667H15.9997C15.0805 20.1667 14.333 20.9142 14.333 21.8333C14.333 22.7525 15.0805 23.5 15.9997 23.5H21.833C22.7522 23.5 23.4997 22.7525 23.4997 21.8333C23.4997 20.9142 22.7522 20.1667 21.833 20.1667ZM21.833 14.3333H15.9997C15.0805 14.3333 14.333 15.0808 14.333 16C14.333 16.9192 15.0805 17.6667 15.9997 17.6667H21.833C22.7522 17.6667 23.4997 16.9192 23.4997 16C23.4997 15.0808 22.7522 14.3333 21.833 14.3333ZM21.833 8.5H15.9997C15.0805 8.5 14.333 9.2475 14.333 10.1667C14.333 11.0858 15.0805 11.8333 15.9997 11.8333H21.833C22.7522 11.8333 23.4997 11.0858 23.4997 10.1667C23.4997 9.2475 22.7522 8.5 21.833 8.5Z"
        fill="#FD5D29"
      />
      <path
        d="M10.1663 23.9167C11.3169 23.9167 12.2497 22.9839 12.2497 21.8333C12.2497 20.6827 11.3169 19.75 10.1663 19.75C9.01575 19.75 8.08301 20.6827 8.08301 21.8333C8.08301 22.9839 9.01575 23.9167 10.1663 23.9167Z"
        fill="#FD5D29"
      />
      <path
        d="M10.1663 18.0833C11.3169 18.0833 12.2497 17.1506 12.2497 16C12.2497 14.8494 11.3169 13.9166 10.1663 13.9166C9.01575 13.9166 8.08301 14.8494 8.08301 16C8.08301 17.1506 9.01575 18.0833 10.1663 18.0833Z"
        fill="#FD5D29"
      />
      <path
        d="M10.1663 12.25C11.3169 12.25 12.2497 11.3173 12.2497 10.1667C12.2497 9.01611 11.3169 8.08337 10.1663 8.08337C9.01575 8.08337 8.08301 9.01611 8.08301 10.1667C8.08301 11.3173 9.01575 12.25 10.1663 12.25Z"
        fill="#FD5D29"
      />
    </svg>
  );
};

export const UserSVG = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.801"
        cy="9.60004"
        r="4.26667"
        fill={color}
        //
      />
      <ellipse
        cx="12.801"
        cy="21.3344"
        rx="7.46667"
        ry="4.26667"
        fill={color}
        //
      />
      <path
        d="M25.5996 21.3338C25.5996 23.1011 23.4283 24.5338 20.7774 24.5338C21.5584 23.6801 22.0954 22.6085 22.0954 21.3353C22.0954 20.0606 21.5572 18.988 20.7747 18.1338C23.4256 18.1338 25.5996 19.5665 25.5996 21.3338Z"
        fill={color}
        //
      />
      <path
        d="M22.3996 9.60076C22.3996 11.3681 20.9669 12.8008 19.1996 12.8008C18.8142 12.8008 18.4448 12.7326 18.1026 12.6078C18.6072 11.7202 18.8954 10.6935 18.8954 9.59957C18.8954 8.5064 18.6077 7.48044 18.1037 6.59333C18.4456 6.46873 18.8146 6.40076 19.1996 6.40076C20.9669 6.40076 22.3996 7.83345 22.3996 9.60076Z"
        fill={color}
        //
      />
    </svg>
  );
};
export const BusinessSVG = ({ color }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46447 6.46447C5 7.92893 5 10.286 5 15C5 19.714 5 22.0711 6.46447 23.5355C7.92893 25 10.286 25 15 25C19.714 25 22.0711 25 23.5355 23.5355C25 22.0711 25 19.714 25 15C25 10.286 25 7.92893 23.5355 6.46447C22.0711 5 19.714 5 15 5C10.286 5 7.92893 5 6.46447 6.46447ZM16.75 13C16.75 13.4142 17.0858 13.75 17.5 13.75H18.1893L16.1768 15.7626C16.0791 15.8602 15.9209 15.8602 15.8232 15.7626L14.2374 14.1768C13.554 13.4934 12.446 13.4934 11.7626 14.1768L9.46967 16.4697C9.17678 16.7626 9.17678 17.2374 9.46967 17.5303C9.76256 17.8232 10.2374 17.8232 10.5303 17.5303L12.8232 15.2374C12.9209 15.1398 13.0791 15.1398 13.1768 15.2374L14.7626 16.8232C15.446 17.5066 16.554 17.5066 17.2374 16.8232L19.25 14.8107V15.5C19.25 15.9142 19.5858 16.25 20 16.25C20.4142 16.25 20.75 15.9142 20.75 15.5V13C20.75 12.5858 20.4142 12.25 20 12.25H17.5C17.0858 12.25 16.75 12.5858 16.75 13Z"
        fill={color}
      />
    </svg>
  );
};

export const CategorySVG = ({ color }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46447 6.46447C5 7.92893 5 10.286 5 15C5 19.714 5 22.0711 6.46447 23.5355C7.92893 25 10.286 25 15 25C19.714 25 22.0711 25 23.5355 23.5355C25 22.0711 25 19.714 25 15C25 10.286 25 7.92893 23.5355 6.46447C22.0711 5 19.714 5 15 5C10.286 5 7.92893 5 6.46447 6.46447ZM13.5431 10.5172C13.8288 10.2173 13.8172 9.74256 13.5172 9.4569C13.2173 9.17123 12.7426 9.18281 12.4569 9.48276L10.1429 11.9125L9.5431 11.2828C9.25744 10.9828 8.78271 10.9712 8.48276 11.2569C8.18281 11.5426 8.17123 12.0173 8.4569 12.3172L9.59976 13.5172C9.74131 13.6659 9.9376 13.75 10.1429 13.75C10.3481 13.75 10.5444 13.6659 10.686 13.5172L13.5431 10.5172ZM16 11.25C15.5858 11.25 15.25 11.5858 15.25 12C15.25 12.4142 15.5858 12.75 16 12.75H21C21.4142 12.75 21.75 12.4142 21.75 12C21.75 11.5858 21.4142 11.25 21 11.25H16ZM13.5431 17.5172C13.8288 17.2173 13.8172 16.7426 13.5172 16.4569C13.2173 16.1712 12.7426 16.1828 12.4569 16.4828L10.1429 18.9125L9.5431 18.2828C9.25744 17.9828 8.78271 17.9712 8.48276 18.2569C8.18281 18.5426 8.17123 19.0173 8.4569 19.3172L9.59976 20.5172C9.74131 20.6659 9.9376 20.75 10.1429 20.75C10.3481 20.75 10.5444 20.6659 10.686 20.5172L13.5431 17.5172ZM16 18.25C15.5858 18.25 15.25 18.5858 15.25 19C15.25 19.4142 15.5858 19.75 16 19.75H21C21.4142 19.75 21.75 19.4142 21.75 19C21.75 18.5858 21.4142 18.25 21 18.25H16Z"
        fill={color}
      />
    </svg>
  );
};

export const ContentSVG = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.6497 6.58317C6.40002 7.83285 6.40002 9.84418 6.40002 13.8668V18.1335C6.40002 22.1561 6.40002 24.1675 7.6497 25.4172C8.89938 26.6668 10.9107 26.6668 14.9334 26.6668H17.0667C21.0893 26.6668 23.1007 26.6668 24.3503 25.4172C25.6 24.1675 25.6 22.1561 25.6 18.1335V13.8668C25.6 9.84418 25.6 7.83285 24.3503 6.58317C23.1007 5.3335 21.0893 5.3335 17.0667 5.3335H14.9334C10.9107 5.3335 8.89938 5.3335 7.6497 6.58317ZM10.9334 11.7335C10.9334 11.2917 11.2915 10.9335 11.7334 10.9335H20.2667C20.7085 10.9335 21.0667 11.2917 21.0667 11.7335C21.0667 12.1753 20.7085 12.5335 20.2667 12.5335H11.7334C11.2915 12.5335 10.9334 12.1753 10.9334 11.7335ZM10.9334 16.0002C10.9334 15.5583 11.2915 15.2002 11.7334 15.2002H20.2667C20.7085 15.2002 21.0667 15.5583 21.0667 16.0002C21.0667 16.442 20.7085 16.8002 20.2667 16.8002H11.7334C11.2915 16.8002 10.9334 16.442 10.9334 16.0002ZM11.7334 19.4668C11.2915 19.4668 10.9334 19.825 10.9334 20.2668C10.9334 20.7087 11.2915 21.0668 11.7334 21.0668H17.0667C17.5085 21.0668 17.8667 20.7087 17.8667 20.2668C17.8667 19.825 17.5085 19.4668 17.0667 19.4668H11.7334Z"
        fill={color}
      />
    </svg>
  );
};

export const LogoutSVG = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4001 15.9998C20.4001 15.558 20.042 15.1998 19.6001 15.1998L7.89608 15.1998L9.98744 13.4072C10.3229 13.1197 10.3617 12.6147 10.0742 12.2792C9.78667 11.9437 9.28163 11.9049 8.94617 12.1924L5.21284 15.3924C5.03552 15.5444 4.93347 15.7663 4.93347 15.9998C4.93347 16.2334 5.03552 16.4553 5.21284 16.6072L8.94617 19.8072C9.28163 20.0948 9.78667 20.0559 10.0742 19.7205C10.3617 19.385 10.3229 18.88 9.98744 18.5924L7.89608 16.7998L19.6001 16.7998C20.042 16.7998 20.4001 16.4417 20.4001 15.9998Z"
        fill={color}
      />
      <path
        d="M13.2001 11.7332C13.2001 12.4822 13.2001 12.8567 13.3799 13.1257C13.4577 13.2422 13.5577 13.3422 13.6742 13.42C13.9433 13.5998 14.3178 13.5998 15.0668 13.5998L19.6001 13.5998C20.9256 13.5998 22.0001 14.6743 22.0001 15.9998C22.0001 17.3253 20.9256 18.3998 19.6001 18.3998L15.0669 18.3998C14.3178 18.3998 13.9432 18.3998 13.6742 18.5796C13.5577 18.6574 13.4577 18.7574 13.3799 18.8738C13.2001 19.1429 13.2001 19.5174 13.2001 20.2665C13.2001 23.2835 13.2001 24.792 14.1374 25.7292C15.0747 26.6665 16.5829 26.6665 19.5999 26.6665L20.6666 26.6665C23.6836 26.6665 25.192 26.6665 26.1293 25.7292C27.0666 24.792 27.0666 23.2835 27.0666 20.2665L27.0666 11.7332C27.0666 8.71619 27.0666 7.20768 26.1293 6.27043C25.1921 5.33317 23.6836 5.33317 20.6666 5.33317L19.5999 5.33317C16.5829 5.33317 15.0747 5.33317 14.1374 6.27043C13.2001 7.20769 13.2001 8.71618 13.2001 11.7332Z"
        fill={color}
      />
    </svg>
  );
};
export const LogoutModalSVG = ({ color }) => {
  return (
    <svg
      width="89"
      height="90"
      viewBox="0 0 89 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_780_6405)">
        <rect
          x="-1.52588e-05"
          y="0.806152"
          width="88.3343"
          height="88.3343"
          rx="20"
          fill="white"
        />
        <circle
          cx="44.0547"
          cy="45.0005"
          r="44"
          fill="#FD5D29"
          fill-opacity="0.2"
        />
        <g clip-path="url(#clip1_780_6405)">
          <path
            d="M51.6794 48.209C50.7347 48.209 49.9712 48.9744 49.9712 49.9172V56.7507C49.9712 57.6919 49.2058 58.4589 48.2627 58.4589H43.1377V32.8341C43.1377 31.3752 42.2084 30.0718 40.8111 29.5866L40.3053 29.4174H48.2627C49.2058 29.4174 49.9712 30.1844 49.9712 31.1259V36.2509C49.9712 37.1937 50.7347 37.9591 51.6794 37.9591C52.6241 37.9591 53.3876 37.1937 53.3876 36.2509V31.1259C53.3876 28.3004 51.0882 26.001 48.2627 26.001H29.8984C29.8333 26.001 29.7789 26.0301 29.7157 26.0385C29.6334 26.0316 29.5549 26.001 29.4714 26.001C27.5871 26.001 26.0547 27.5331 26.0547 29.4174V60.1671C26.0547 61.626 26.984 62.9294 28.3813 63.4146L38.6622 66.8416C39.0106 66.9492 39.3572 67.0005 39.7213 67.0005C41.6056 67.0005 43.1377 65.4681 43.1377 63.5838V61.8756H48.2627C51.0882 61.8756 53.3876 59.5762 53.3876 56.7507V49.9172C53.3876 48.9744 52.6241 48.209 51.6794 48.209Z"
            fill="#FD5D29"
          />
          <path
            d="M66.5538 41.8762L59.7203 35.043C59.232 34.5544 58.4972 34.4074 57.8585 34.672C57.2213 34.937 56.8044 35.5604 56.8044 36.2507V41.3757H49.9712C49.0281 41.3757 48.2627 42.1408 48.2627 43.0839C48.2627 44.027 49.0281 44.7921 49.9712 44.7921H56.8044V49.917C56.8044 50.6074 57.2213 51.2308 57.8585 51.4957C58.4972 51.7604 59.232 51.6134 59.7203 51.1251L66.5538 44.2916C67.2216 43.6238 67.2216 42.544 66.5538 41.8762Z"
            fill="#FD5D29"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_780_6405">
          <rect
            x="-1.52588e-05"
            y="0.806152"
            width="88.3343"
            height="88.3343"
            rx="20"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_780_6405">
          <rect
            width="41"
            height="41"
            fill="white"
            transform="translate(26.0547 26.0005)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const ContactSVG = ({ color }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0376 8.31617L13.6866 9.4791C14.2723 10.5286 14.0372 11.9053 13.1147 12.8278C13.1147 12.8278 13.1147 12.8278 13.1147 12.8278C13.1146 12.8279 11.9959 13.9468 14.0245 15.9755C16.0525 18.0035 17.1714 16.8861 17.1722 16.8853C17.1722 16.8853 17.1722 16.8853 17.1722 16.8853C18.0947 15.9628 19.4714 15.7277 20.5209 16.3134L21.6838 16.9624C23.2686 17.8468 23.4557 20.0692 22.0628 21.4622C21.2258 22.2992 20.2004 22.9505 19.0669 22.9934C17.1588 23.0658 13.9183 22.5829 10.6677 19.3323C7.41713 16.0817 6.93421 12.8412 7.00655 10.9331C7.04952 9.7996 7.7008 8.77423 8.53781 7.93723C9.93076 6.54428 12.1532 6.73144 13.0376 8.31617Z"
        fill={color}
      />
      <path
        d="M16.2595 4.88008C16.3257 4.47119 16.7122 4.19381 17.1211 4.26001C17.1464 4.26485 17.2279 4.28007 17.2705 4.28958C17.3559 4.30858 17.4749 4.33784 17.6233 4.38106C17.9201 4.46751 18.3347 4.60991 18.8323 4.83805C19.8286 5.2948 21.1544 6.09381 22.5302 7.46961C23.906 8.84541 24.705 10.1712 25.1617 11.1675C25.3899 11.6651 25.5323 12.0797 25.6187 12.3765C25.6619 12.5249 25.6912 12.6439 25.7102 12.7293C25.7197 12.7719 25.7267 12.8062 25.7315 12.8315L25.7373 12.8627C25.8034 13.2716 25.5286 13.6741 25.1197 13.7403C24.712 13.8063 24.3279 13.5303 24.2601 13.1233C24.258 13.1124 24.2522 13.083 24.2461 13.0553C24.2337 12.9999 24.2124 12.9121 24.1786 12.796C24.1109 12.5636 23.9934 12.2183 23.7982 11.7926C23.4084 10.9423 22.7074 9.76813 21.4695 8.53027C20.2317 7.2924 19.0575 6.59141 18.2072 6.20158C17.7815 6.00642 17.4362 5.88889 17.2038 5.82122C17.0877 5.78739 16.9417 5.75387 16.8863 5.74154C16.4793 5.67372 16.1935 5.2878 16.2595 4.88008Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4857 8.32954C16.5995 7.93126 17.0146 7.70065 17.4129 7.81444L17.2069 8.53558C17.4129 7.81444 17.4129 7.81444 17.4129 7.81444L17.4144 7.81485L17.4159 7.81529L17.4192 7.81627L17.427 7.81858L17.4468 7.82475C17.4618 7.82957 17.4807 7.83585 17.5031 7.84381C17.548 7.85975 17.6074 7.88242 17.6802 7.91362C17.8259 7.97605 18.0249 8.07248 18.2695 8.21719C18.7589 8.50687 19.4271 8.98805 20.2121 9.77302C20.9971 10.558 21.4782 11.2262 21.7679 11.7156C21.9126 11.9602 22.009 12.1592 22.0715 12.3049C22.1027 12.3777 22.1254 12.4371 22.1413 12.482C22.1493 12.5044 22.1555 12.5233 22.1604 12.5383L22.1665 12.5581L22.1688 12.5659L22.1698 12.5692L22.1702 12.5707C22.1702 12.5707 22.1707 12.5722 21.4495 12.7782L22.1707 12.5722C22.2845 12.9705 22.0538 13.3856 21.6556 13.4994C21.2607 13.6122 20.8492 13.3864 20.7313 12.9944L20.7276 12.9836C20.7223 12.9686 20.7113 12.939 20.6928 12.8958C20.6558 12.8094 20.5887 12.6682 20.4771 12.4796C20.2541 12.1029 19.8514 11.5336 19.1514 10.8337C18.4515 10.1337 17.8822 9.73102 17.5055 9.50805C17.3169 9.39644 17.1757 9.32934 17.0893 9.29234C17.0461 9.27382 17.0165 9.26279 17.0015 9.25746L16.9907 9.25376C16.5987 9.13588 16.3729 8.72443 16.4857 8.32954Z"
        fill={color}
      />
    </svg>
  );
};
