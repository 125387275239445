export const RouteList = {
  login: "/login",
  dashboard: "/",
  user: "/user",
  business:"/business",
  content: "/content",
  inquiry: "/inquiry",
  userDetails: "/user/details",
  category:"/category"
};
