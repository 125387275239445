import { Box, CircularProgress } from "@mui/material";
import React from "react";

const Loader = () => {
    return (
        <Box
            className="flexCenter"
            sx={{
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                height: "100%",
                width: "100%",
            }}
        >
            <CircularProgress style={{ color: "#FD5D29" }} />
        </Box>
    );
};

export default Loader;
