import { useNavigate } from "react-router-dom";
import { RouteList } from "./RoutesList";
import { useState, useEffect } from "react";

const ProtectedRoutes = ({ component }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("Megalopolist-userData"));
    if (user) {
      setIsAuthenticated(true);
    } else {
      navigate(RouteList.login);
    }
    setLoading(false);
  }, [navigate]);

  if (loading) {
    return null;
  }

  return isAuthenticated ? component : null;
};

export default ProtectedRoutes;
