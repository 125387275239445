import React from "react";
import { Route, Routes } from "react-router-dom";
import { RouteList } from "./RoutesList";
import DashboardScreen from "../Page/Dashboard/DashboardScreen";
import MainLayout from "../Page/MainLayout/Layout";
import ProtectedRoutes from "./ProtectedRoutes";
import UserScreen from "../Page/User/UserScreen";
import ContentScreen from "../Page/Content/ContentScreen";
import InquiryScreen from "../Page/Inquiry/InquiryScreen";
import LoginScreen from "../Page/Auth/LoginScreen";
import BusinessScreen from "../Page/Business/BusinessScreen";
import CategoryScreen from "../Page/Category/CategoryScreen";
import AuthScreen from "../Page/Auth/Auth";

const AllRoutes = () => {
  const routes = [
    {
      path: RouteList.dashboard,
      element: <DashboardScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.user,
      element: <UserScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.business,
      element: <BusinessScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.category,
      element: <CategoryScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.content,
      element: <ContentScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.inquiry,
      element: <InquiryScreen />,
      isPrivate: true,
    },
  ];

  const routesWithFullScreen = [
    {
      path: RouteList.login,
      element: <AuthScreen />,
      isPrivate: false,
    },
  ];

  return (
    <Routes>
      {routes.map((route, i) => (
        <Route
          key={"screen" + i}
          path={route.path}
          element={
            <MainLayout
              subElement={<ProtectedRoutes component={route.element} />}
            />
          }
        />
      ))}
      {routesWithFullScreen.map((route, i) => (
        <Route
          key={"fullScreen-" + i}
          path={route.path}
          element={route.element}
        />
      ))}
    </Routes>
  );
};

export default AllRoutes;
