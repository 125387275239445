import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SideMenubar from "./SideMenubar";
import { useState } from "react";
import {
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ConfirmationModal from "../../Component/ConfirmationModal";
import logo from "../../Assets/Image/logo.webp";
import { LogoutSVG } from "../../Component/SVGs";
import { RouteList } from "../../Component/RoutesList";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const drawerWidth = 240;

function MainLayout({ subElement }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const nav = useNavigate();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        flexDirection: "column",
        width: "100%",
        background: "#FD5D291A",
      }}
    >
      {/* side menu bar component */}
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box className="flexCenter" sx={{ m: "1rem auto 0.5rem", pt: "20px" }}>
          <img src={logo} alt="logo" style={{ height: "100px" }} />
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 220px)",
            overflow: "auto",
          }}
        >
          <SideMenubar />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          py: 1,
        }}
      >
        <ConfirmationModal
          handleOpenButton={() => (
            <ListItem
              disablePadding
              sx={{
                background: "transparent",
                width: "100%",
                "&:hover": {
                  outline: "1px solid #FF602D",
                  boxSizing: "border-box",
                },
              }}
            >
              <ListItemButton
                sx={{
                  "& .MuiListItemIcon-root": {
                    minWidth: "40px",
                  },
                  "& .MuiTypography-root": {
                    fontWeight: 600,
                  },
                }}
              >
                <ListItemIcon>{<LogoutSVG color={"#00000099"} />}</ListItemIcon>
                <ListItemText
                  sx={{
                    fontWeight: 600,
                    color: "#00000099",
                    "&:hover": {},
                  }}
                  primary={"Logout"}
                />
              </ListItemButton>
            </ListItem>
          )}
          title="Logout"
          message="Are you sure you want to logout?"
          confirmText="Logout"
          cancelText="Cancel"
          onConfirm={() => {
            localStorage.clear();
            nav(RouteList.login);
            toast.success("Logout Successfully");
          }}
        />
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          ml: { sm: `${drawerWidth}px` },
          zIndex: 1,
          display: { sm: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Megalopolist Admin
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, zIndex: 0 }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/* <Toolbar /> */}
        {/* <Box sx={{ border: "solid red" }}><Element /></Box> */}
        {subElement}
      </Box>
    </Box>
  );
}

export default MainLayout;
