import { Box, Typography } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useRef, useState } from "react";
import DropDownComponent from "../../Component/DropDownComponent";
import { toast } from "react-toastify";
import { apiList } from "../../Component/apiList";
import { AXIOS } from "../../Component/apiHelper";
import moment from "moment";

import defaultUserImage from "../../Assets/Image/profile.png";
import view from "../../Assets/Image/view.svg";
import block from "../../Assets/Image/block.svg";

const CategoryScreen = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filter, setFilter] = useState("status != -1 and role != 3");
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filterOption = [
    { label: "All", value: "status != -1 and role != 3" },
    { label: "User", value: "status != -1 and role = 1" },
    { label: "Business", value: "status != -1 and role = 2" },
    { label: "Block", value: "status = -1 and role != 3" },
  ];

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      renderCell: ({ row }) => (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
          }}
        >
          {row?.rowid + page * rowsPerPage}
        </div>
      ),
    },
    {
      field: "image",
      headerName: "Image",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={
              cell?.row?.image
                ? apiList.baseURL + cell?.row?.image
                : defaultUserImage
            }
            alt="cate_image"
            style={{ borderRadius: "50%", height: "35px", width: "35px" }}
          />
        </div>
      ),
    },
    {
      field: "category_name",
      headerName: "Main Category ",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      flex: 1,
      display: "flex",
      renderCell: ({ row }) => (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
          }}
        >
          {row?.category_name}
        </div>
      ),
    },
    {
      field: "sub",
      headerName: "Sub Category ",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      flex: 1,
      display: "flex",
      renderCell: ({ row }) => (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
          }}
        >
          {row?.category_name}
        </div>
      ),
    },

    {
      field: "status",
      headerName: "Action",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (cell) => {
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
              <img src={block} alt="Block" />
              <img
                src={view}
                style={{ height: "25px", width: "25px" }}
                alt="View"
              />
            </Box>
          </Box>
        );
      },
    },
  ];

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    filter,
    prevFilter,
    search,
    prevSearch
  ) => {
    try {
      const count = page * rowsPerPage;
      setLoading(true);
      const res = await AXIOS.get(
        // `${apiList.user.getAll}&arg=status != ? and role != ?&prms=[${filter},3]&order=created_at desc&skip=${count}&limit=${rowsPerPage}&total=1`
        `${apiList.category.getAll}`
      );
      if (res?.s) {
        // const updatedData = count === 0 ? res?.r : [...data, ...res?.r];
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const searchRef = useRef(debouncedSearchTerm);
  const filterRef = useRef(filter);

  useEffect(() => {
    getData(
      page,
      rowsPerPage,
      filter,
      filterRef.current,
      debouncedSearchTerm,
      searchRef.current
    );
    searchRef.current = debouncedSearchTerm;
    filterRef.current = filter;
  }, [debouncedSearchTerm, filter, rowsPerPage, page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setPage(0);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box className="flexBetween">
        <Typography>
          <h2> Category Management </h2>
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: "270px",
              width: "100%",
            }}
          >
            <input
              type="search"
              name="search"
              value={searchTerm}
              placeholder="Search"
              autoFocus
              style={{
                padding: "13px",
                height: "100%",
                borderRadius: "12px",
                border: "1px solid #FD5D29",
                backgroundColor: "#FFF6F3",
                color: "#FD5D29",
                width: "100%",
                maxWidth: "350px",
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
              id="search"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DropDownComponent
              value={filter}
              options={filterOption}
              modal={false}
              handleChange={handleFilterChange}
              placeholder={"Status"}
              check={false}
            />
            <DropDownComponent
              value={filter}
              options={filterOption}
              modal={false}
              handleChange={handleFilterChange}
              placeholder={"Status"}
              check={false}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ height: "calc(100vh - 130px)" }}>
        <DataListingComponent
          data={data}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={loading}
          totalData={totalData}
          page={page}
          columns={columns}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          rowHeight="auto"
        />
      </Box>
    </Box>
  );
};

export default CategoryScreen;
