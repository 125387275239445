import { Box, Typography } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useRef, useState } from "react";
import DropDownComponent from "../../Component/DropDownComponent";
import { toast } from "react-toastify";
import { apiList } from "../../Component/apiList";
import { AXIOS } from "../../Component/apiHelper";

import defaultUserImage from "../../Assets/Image/profile.png";
import app from "../../Assets/Image/User Block-1.svg";
import rej from "../../Assets/Image/User Block.svg";
import view from "../../Assets/Image/newView.svg";

const BusinessScreen = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filter, setFilter] = useState("status = 1 ");
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };
  const filterOption = [
    {
      label: "All",
      value: "status = 1 ",
    },
    {
      label: "Pending",
      value: "status = 1 and is_approve = 0  ",
    },
    {
      label: "Approved",
      value: "status = 1 and is_approve = 1  ",
    },
    {
      label: "Rejected",
      value: "status = 1 and is_approve = -1  ",
    },
  ];

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 80,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid + page * rowsPerPage;
      },
    },
    {
      field: "profile_img",
      headerName: "Profile",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={
              cell?.row?.user_details?.profile_img
                ? apiList.baseURL + cell?.row?.user_details?.profile_img
                : defaultUserImage
            }
            alt="profile"
            style={{ borderRadius: "12px", height: "35px", width: "35px" }}
          />
        </div>
      ),
    },
    {
      field: "username",
      headerName: "User Name",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
      renderCell: (cell) => {
        return cell?.row?.user_details?.username;
      },
    },
    {
      field: "business_name",
      headerName: "Business Name",
      minWidth: 250,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "category_name",
      headerName: "Category Name",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },

    {
      field: "apprrove",
      headerName: "Action",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const s = cell?.row?.user_details?.is_approve;
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            {s == 0 ? (
              <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
                <img
                  src={rej}
                  alt="Block"
                  style={{ height: "25px", width: "25px" }}
                  onClick={() =>
                    handleBusinessAction(cell?.row?.user_details?.id, 1)
                  }
                />
                <img
                  src={app}
                  style={{ height: "25px", width: "25px" }}
                  alt="View"
                  onClick={() =>
                    handleBusinessAction(cell?.row?.user_details?.id, -1)
                  }
                />
              </Box>
            ) : s === 1 ? (
              <span style={{ color: "green" }}>Approved</span>
            ) : (
              s === -1 && <span style={{ color: "red" }}>Rejected</span>
            )}
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "View",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
              {/* <img src={block} alt="Block" /> */}
              <img
                src={view}
                style={{ height: "25px", width: "25px" }}
                alt="View"
              />
            </Box>
          </Box>
        );
      },
    },
  ];

  const handleBusinessAction = async (userId, status) => {
    try {
      const res = await AXIOS.post(`${apiList.business.action}/${userId}`, {
        is_approve: status,
      });
      if (res?.s) {
        status === 1 ? toast.success("Approved") : toast.error("Rejected");
        getData(
          page,
          rowsPerPage,
          filter,
          filterRef.current,
          debouncedSearchTerm,
          searchRef.current
        );
      } else {
        toast.error(res?.m);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    filter,
    prevFilter,
    search,
    prevSearch
  ) => {
    try {
      const count = page * rowsPerPage;
      setLoading(true);
      if (filter !== prevFilter || search !== prevSearch) {
        setPage(0);
        setData([]);
      }
      let arg =
        search?.length > 0
          ? `${filter} and user_id in (select user_id FROM user_identity where user_id = user_business_view.user_id) and (business_name  LIKE '%25${search}%25')`
          : `${filter} and user_id in (select user_id FROM user_identity where user_id = user_business_view.user_id)`;
      const res = await AXIOS.get(
        `/api/base/get/?select=*&from=user_business_view&arg=${arg}&childEntity={"user_details":{"select":"id,username,profile_img,is_approve","from":"user_view","arg":"id = ?","limit":1,"prms":[":;user_id"]}}&order=created_at desc&skip=${count}&limit=${rowsPerPage}&total=1`
      );
      if (res?.s) {
        // const updatedData = count === 0 ? res?.r : [...data, ...res?.r];
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const searchRef = useRef(debouncedSearchTerm);
  const filterRef = useRef(filter);

  useEffect(() => {
    getData(
      page,
      rowsPerPage,
      filter,
      filterRef.current,
      debouncedSearchTerm,
      searchRef.current
    );
    searchRef.current = debouncedSearchTerm;
    filterRef.current = filter;
  }, [debouncedSearchTerm, filter, rowsPerPage, page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setPage(0);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box className="flexBetween">
        <Typography>
          <h2> Business Management </h2>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "470px",
            width: "100%",
          }}
        >
          <input
            type="search"
            name="search"
            value={searchTerm}
            placeholder="Search"
            autoFocus
            style={{
              padding: "13px",
              height: "100%",
              borderRadius: "12px",
              border: "1px solid #FD5D29",
              backgroundColor: "#FFF6F3",
              color: "#FD5D29",
              width: "100%",
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            id="search"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DropDownComponent
            value={filter}
            options={filterOption}
            modal={false}
            handleChange={handleFilterChange}
            placeholder={"All"}
            check={false}
          />
          {/* <DropDownComponent
              value={filter}
              options={filterOption}
              modal={false}
              handleChange={handleFilterChange}
              placeholder={"Status"}
              check={false}
            /> */}
        </Box>
      </Box>
      <Box sx={{ height: "calc(100vh - 180px)" }}>
        <DataListingComponent
          data={data}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={loading}
          totalData={totalData}
          page={page}
          columns={columns}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Box>
  );
};

export default BusinessScreen;
