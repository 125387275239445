export const apiList = {
  baseURL: "https://api.megalopolist.com",
  dashboard: {},
  auth: {
    login: "/api/auth/login",
    forgot: "/api/auth/forgot-password",
    verifyOTP:"/api/auth/check-otp",
    updatePassword:"/api/auth/change-password"
  },
  user: {
    getAll: "/api/base/get/?select=*&from=user_view",
    getDetailById: "/api/user/get-details",
    action: "/api/user/account-status",
  },
  business: {
    getAll: "/api/base/get/?select=*&from=user_view",
    getDetailById: "",
    action: "/api/base/up/user",
  },
  category: {
    getAll: "/api/base/get/?select=*&from=category&arg=status = 1",
  },
};
