import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { RouteList } from "../../Component/RoutesList";
import {
  BusinessSVG,
  CategorySVG,
  ContentSVG,
  DashBoardSVG,
  UserSVG,
} from "../../Component/SVGs";

const SideMenubar = () => {
  const location = useLocation();
  const SideMenuList = [
    {
      id: 1,
      name: "Dashboard",
      link: RouteList.dashboard,
      icon: (color) => <DashBoardSVG color={color} />,
    },
    {
      id: 2,
      name: "User",
      link: RouteList.user,
      icon: (color) => <UserSVG color={color} />,
    },
    {
      id: 2,
      name: "Business",
      link: RouteList.business,
      icon: (color) => <BusinessSVG color={color} />,
    },
    // {
    //   id: 2,
    //   name: "Category",
    //   link: RouteList.category,
    //   icon: (color) => <CategorySVG color={color} />,
    // },
    // {
    //   id: 2,
    //   name: "Content",
    //   link: RouteList.content,
    //   icon: (color) => <ContentSVG color={color} />,
    // },
  ];

  return (
    <List
      sx={{
        gap: 0.9,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {SideMenuList.map(({ name, link, icon }, index) => {
        const isActive = location.pathname === link;
        const iconColor = isActive ? "#FFFFFF" : "#00000099";
        return (
          <Link
            to={link}
            key={index}
            style={{
              textDecoration: "none",
              color: "#000000",
            }}
          >
            <ListItem
              key={name}
              disablePadding
              sx={{
                background: isActive ? "#FF602D" : "transparent",
                color: iconColor,

                // borderRadius: "12px",
                "&:hover": {
                  outline: !isActive ? "1px solid #FF602D" : "none",
                  boxSizing: "border-box",
                  width: "100%",
                },
              }}
            >
              <ListItemButton
                sx={{
                  "& .MuiListItemIcon-root": {
                    minWidth: "55px",
                    pl: 1,
                  },
                  "& .MuiTypography-root": {
                    fontWeight: 600,
                  },
                }}
              >
                <ListItemIcon>{icon(iconColor)}</ListItemIcon>
                <ListItemText sx={{ fontWeight: 600 }} primary={name} />
              </ListItemButton>
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
};

export default SideMenubar;
