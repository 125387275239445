import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import QuillEditerComponent from "../../Component/QuillEditerComponent";
import { useEffect } from "react";
import { AXIOS } from "../../Component/apiHelper";

const ContentScreen = () => {
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const ref = useRef();

  const formik = useFormik({
    initialValues: { content: "" },
    validationSchema: Yup.object({
      content: Yup.string().required("Content is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try
      {
        setLoading(true);
        const formData = new FormData();
        formData.append("content", values.content);
        // formData.append("image", values.image);

        // const res = await POST_API(apiEndpoints.addaboutus, formData);
        if (true)
        {
          toast.success("Updated Successfully");
        }
        setLoading(false);
      } catch (error)
      {
      } finally
      {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  const getData = async (currentTab) => {
    try
    {
      const url = currentTab === 0 ? "Privacy" : "Terms";
      const res = await AXIOS.get(url);
      if (res?.s)
      {
        formik.setFieldValue("content", res?.r);
      } else
      {
        toast.error(res?.m);
      }
    } catch (error)
    {
      console.log(error);
    }
  };


  useEffect(() => {
    // getData(currentTab);
  }, [currentTab]);
  return (
    <>
      <Box className="flexBetween">
        <Typography>
          <h2> Content Management </h2>
        </Typography>
      </Box>
      <Box
        sx={{
          my: 1,
          px: 1,
          display: "flex",
          gap: 2,
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color: currentTab === 0 ? "#FD5D29" : "grey",
            fontWeight: currentTab === 0 ? "600" : "400",
            background: currentTab === 0 ? "#FD5D2945" : "#FFF",
            p: 1,
            borderRadius: "12px",
            cursor: "pointer",
          }}
          onClick={() => setCurrentTab(0)}
        >
          Privacy Policy
        </Box>
        <Box
          sx={{
            color: currentTab === 1 ? "#FD5D29" : "grey",
            fontWeight: currentTab === 1 ? "600" : "400",
            background: currentTab === 1 ? "#FD5D2945" : "#FFF",
            p: 1,
            borderRadius: "12px",
            cursor: "pointer",
          }}
          onClick={() => setCurrentTab(1)}
        >
          Terms & Conditions
        </Box>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <QuillEditerComponent formik={formik} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            mt: "15px", // Add margin-top to separate button from content
          }}
        >
          <Button
            type="submit"
            variant="contained"
            disabled={
              formik.isSubmitting ||
              !formik?.values?.content?.replace(/<[^>]+>/g, "").trim()
            }
            sx={{
              width: "150px",
              textTransform: "none",
              borderRadius: "12px",
              background: "#FD5D29",
              "&:hover": {
                background: `#FD5D29`,
              },
              "&:disabled": {
                background: "#FD5D2990",
                color: "#FFF",
              },
            }}
          >
            {formik.isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default ContentScreen;
