import { Box, Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { RouteList } from "../../Component/RoutesList";

const ForgotPasswordScreen = ({ setCurrentScreen }) => {
  const [loading, setloading] = useState(false);
  const navigate = useNavigate
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        setloading(true);
        const res = await AXIOS.post(apiList.auth.forgot, {
          email: values.email,
        });
        if (res?.s) {
          toast.success(res?.m);
          console.log(res.r);
          localStorage.setItem("fp_token", res?.r?.fp_token);
          localStorage.setItem("email", res?.r?.email);
          setCurrentScreen(3);
          navigate(RouteList?.login, { state: { id: 3 } });
          setloading(false);
        } else {
          setloading(false);
          toast.error(res?.m);
        }
      } catch (error) {
        console.log(error);
        setloading(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <h2 style={{ color: "#000", paddingBottom: "0rem" }}>Forgot password</h2>
      <p style={{ color: "#000000B2", paddingBottom: "1rem" }}>
        Please enter your email address to request a password reset
      </p>
      <Box sx={{ width: { md: "450px" } }}>
        <label htmlFor="email" style={{ fontSize: "14px", paddingLeft: "0px" }}>
          Email
        </label>
        <TextField
          type="email"
          id="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          variant="outlined"
          fullWidth
          focused
          sx={{
            margin: "0.25rem 0 0 0",
            ".MuiInputBase-root": {
              borderRadius: "15px",
              background: "#FD5D2914",
              color: "#000",
              border: "1px solid #FD5D2966",
            },
            ".MuiOutlinedInput-input": {
              padding: "14px",
            },
            ".MuiInputBase-root:hover": {
              background: "#FF8862",
              color: "#fff",
            },
            "& input[type='password']::-ms-reveal": {
              display: "none",
            },
            "& input[type='password']::-ms-clear": {
              display: "none",
            },
            ".MuiOutlinedInput-input:focus": {
              outline: "none",
            },
            fieldset: {
              border: "none",
            },
          }}
          placeholder="Enter your email"
        />
        <Box
          sx={{
            fontSize: "12px",
            px: 1.5,
            letterSpacing: "0.03rem",
            py: 0.25,
            height: "20px", // Maintain consistent height for the error message box
            color:
              formik.touched.email && formik.errors.email
                ? "#d32f2f"
                : "transparent", // Show error color only if there's an error
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {formik.touched.email && formik.errors.email}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            sx={{
              width: "100%",
              mt: 2,
              maxWidth: "330px",
              textTransform: "none",
              borderRadius: "15px",
              fontSize: "17px",
              background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
              "&:hover": {
                background: "#00075",
              },
              padding: "12px",
            }}
          >
            {formik.isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPasswordScreen;
