import { Button, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Loader from "../../Component/Loader";

const UserDetailsModal = ({ id, setOpen, open }) => {
  const handleClose = () => setOpen(false);
  const [data, setData] = useState();
  const [loding, setloding] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  const getDetailsById = async (id) => {
    try
    {
      setloding(true);
      const res = await AXIOS.get(
        `${apiList.user.getDetailById}?user_id=${id}`
      );
      if (res?.s)
      {
        setData(res?.r);
      } else
      {
        toast.error(res?.m);
      }
      setloding(false);
    } catch (error)
    {
      setloding(false);
      console.log(error);
    }
  };

  useEffect(() => {
    id && getDetailsById(id);
  }, [id]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {loding ? (
              <Loader />
            ) : (
              <Box sx={{ display: "flex", gap: 1 }}>
                <Box>
                  name
                </Box>
                <Box>
                  Summary
                </Box>
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default UserDetailsModal;
